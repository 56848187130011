<template>
  <div>
    <header-view
      title="Species"
      previous_page="NO_BACK_BUTTON"
      :btnOptions="[
        {
          name: 'Add Single Species',
          action: () => {
            addSingleDialog = true;
          },
        },
        {
          name: 'Add Species List',
          action: () => {
            addListDialog = true;
          },
        },
      ]" />
    <main-container>
      <add-species-list
        :addSpeciesListDialog="addListDialog"
        @list-added="loadTenantSpecies"
        @close="addListDialog = false" />
      <add-species-single
        :addSpeciesDialog="addSingleDialog"
        @species-added="loadTenantSpecies"
        :speciesList="speciesList"
        @close="addSingleDialog = false" />
      <species-table :speciesList="speciesList"></species-table>
    </main-container>
  </div>
</template>

<script>
  // components
  import SpeciesTable from '@/components/species/species-table';
  import AddSpeciesList from '@/components/species/add-species-list';
  import AddSpeciesSingle from '@/components/species/add-species-single';
  import MainContainer from '@/components/main-container';
  import HeaderView from '@/components/header-view';

  // services
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppSpeciesList',
    components: {
      'species-table': SpeciesTable,
      'add-species-list': AddSpeciesList,
      'add-species-single': AddSpeciesSingle,
      'main-container': MainContainer,
      'header-view': HeaderView,
    },
    data() {
      return {
        //tenant list display data
        //list of trees in tenant species list
        speciesList: [],
        addListDialog: false,
        addSingleDialog: false,
      };
    },
    computed: {},
    mounted() {
      this.loadTenantSpecies(this.$auth.userProfile.tenant_uuid);
    },
    methods: {
      //tenant list display methods
      //load tenant's species list
      //load tenant's species list
      async loadTenantSpecies(tenant_uuid) {
        // execute database query
        var res_tenant_species = await Tenants.getTenantSpecies(tenant_uuid);

        // validate
        if (res_tenant_species) {
          // console.log( "tenant species: ", res_tenant_species )

          // get just the species uuids
          var speciesUUIDs = res_tenant_species.map((x) => x.species_uuid);

          //this should probable just be called for each uuid rather than as a group so that
          //there doesn't have to be the rematching stuff
          //translate the uuids in the master list to get common and latin name
          var res_master_species =
            await Tenants.getMasterSpeciesBySpeciesLookup({
              species_uuids: speciesUUIDs,
            });

          // validate
          if (res_master_species) {
            //save species response to species data table
            this.speciesList = res_master_species;

            //this rematches the tenant list uuid to the translated master list display
            //this is for alt name display and view purposes, but could probably be done in a much smarter way
            this.speciesList.forEach((row) => {
              var match = res_tenant_species.find((tenantSpecies) => {
                return tenantSpecies.species_uuid === row.uuid;
              });
              row.tenantSpeciesUUID = match.uuid;
              //if match has an alternate common name, make the row common name that
              row.common_name = match.alt_common_name
                ? match.alt_common_name
                : row.common_name;
              row.active = match.active;
            });
          } else {
            console.log('failed to load master species');
          }
        } else {
          console.log('failed to load tenant species');
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
