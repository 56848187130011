var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-view',{attrs:{"title":"Species","previous_page":"NO_BACK_BUTTON","btnOptions":[
      {
        name: 'Add Single Species',
        action: function () {
          _vm.addSingleDialog = true;
        },
      },
      {
        name: 'Add Species List',
        action: function () {
          _vm.addListDialog = true;
        },
      } ]}}),_c('main-container',[_c('add-species-list',{attrs:{"addSpeciesListDialog":_vm.addListDialog},on:{"list-added":_vm.loadTenantSpecies,"close":function($event){_vm.addListDialog = false}}}),_c('add-species-single',{attrs:{"addSpeciesDialog":_vm.addSingleDialog,"speciesList":_vm.speciesList},on:{"species-added":_vm.loadTenantSpecies,"close":function($event){_vm.addSingleDialog = false}}}),_c('species-table',{attrs:{"speciesList":_vm.speciesList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }